import Swiper from "swiper/bundle"
import $ from "jquery"

new Swiper($('.who-we-are .swiper').get(0), {
  slidesPerView: 1,
  spaceBetween: 30,
  breakpoints: {
    768: { slidesPerView: 2 },
    992: { slidesPerView: 3 },
    1200: { slidesPerView: 4 }
  }
})
