import "bootstrap.scss"
import "swiper/css/bundle"
import "site.scss"

import $ from "jquery"
import "bootstrap"


// 加载所有组件
const componentsContext = require.context("bridgetownComponents", true, /\.(js|css|scss)$/)
componentsContext.keys().forEach(componentsContext)

// 加载所有页面
const pagesContext = require.context("page", false, /\.js$/)
pagesContext.keys()
  .filter(it => /^page/.test(it))
  .forEach(it => {
    const name = it.match(/^page\/(.+)\.js$/)[1]
    const element = $(`.${name}-page`).get(0)
    if (element) { pagesContext(it) }
  })
