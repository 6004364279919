import $ from "jquery"

function updateNews (html) {
  const $fragment = $(html)
  const $loadMore = $fragment.find('#news-load-more')

  $fragment.find('#news-list').children().appendTo('#news-list')

  if ($loadMore.get(0)) {
    $('#news-load-more').replaceWith($loadMore)
  } else {
    $('#news-load-more').parent().remove()
  }
}

$('.news-page').on('click', '#news-load-more', ev => {
  ev.preventDefault()

  const url = $('#news-load-more').attr('href')

  $.ajax(url).done(updateNews)
})
