import Swiper from "swiper/bundle"
import $ from "jquery"

$('.cross-platform').each((_idx, element) => {
  const $element = $(element)
  const $swiper = $element.find('.swiper')

  new Swiper($swiper.get(0), {
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      prevEl: $element.find(".swiper-navigation-prev").get(0),
      nextEl: $element.find(".swiper-navigation-next").get(0)
    },
    breakpoints: {
      768: { slidesPerView: 2 },
      992: { slidesPerView: 3 },
      1200: { slidesPerView: 4 }
    }
  })
})
