import Swiper from "swiper/bundle"
import $ from "jquery"

new Swiper($('.recommended-solutions .swiper').get(0), {
  slidesPerView: 1,
  spaceBetween: 30,
  navigation: {
    prevEl: $('.recommended-solutions .swiper-navigation-prev').get(0),
    nextEl: $('.recommended-solutions .swiper-navigation-next').get(0)
  },
  breakpoints: {
    768: { slidesPerView: 2 },
    992: { slidesPerView: 3 }
  }
})
